import React from 'react'
import { graphql, Link} from 'gatsby'
import Img from 'gatsby-image'
import Layout from "../components/layout"
import SEO from "../components/seo"


const Post = ({ data }) => {
    const post = data.post;
    const immagini = data.post.immagini || [];
    return (
        <Layout>
            <SEO title={post.titolo} immagineHero={post.copertina}/>

            {!!post.testo &&
                <div className="section  blog">
                    <div className="row">
                        <div className="col-12">
                            <Img fluid={post.copertina.fluid}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-8 offset-lg-2">
                            {/* <img alt={post.titolo} className="blog__interna" src={post.copertina.file.url} /> */}
                            <h1 className="pagetitle">{post.titolo}</h1>
                            <div className="blog__date">{post.data}</div>
                            <div className="blog__abstract">{post.abstract}</div>
                            <div className="section__text blog__text" dangerouslySetInnerHTML={{__html: post.testo.childMarkdownRemark.html}} />
                        </div>
                    </div>
                </div>
            }
            <div className="row">
                {immagini.map((item,index) => {
                    return (
                        <div key={index} className={`col-sm-${immagini.length===1?'12':'6'} text-center`}>
                            <div className="card">
                                <Img  fluid={item.fluid} />
                                <div className="card__caption">{item.description}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="section">
                <div className="row">
                    <div className="col-12 text-center">
                        {/* <a href="/blog/" onClick={goTo} className="btn-action">Torna indietro</a> */}
                        <Link to="/blog/" className="btn-action">Torna indietro</Link>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query PostQuery($contentful_id: String!, $locale: String!) {
        post: contentfulArticoliBlog(contentful_id: {eq: $contentful_id}, node_locale: {eq: $locale}){
            titolo
            abstract
            copertina {
                fluid(maxWidth:1240, quality:70) {
                    ...GatsbyContentfulFluid_withWebp
                }
                file {
                    url
                }
            }
            testo {
                childMarkdownRemark {
                    excerpt (pruneLength: 200)
                    html
                }
            }
            immagini {
                fluid(maxWidth:1200, quality:70) {
                    ...GatsbyContentfulFluid
                }
                description
            } 
            categoria
            data(formatString: "DD MMMM, YYYY", locale:"it_IT")
        }  
    }
`;

export default Post;
